import { AppThunk } from '../../../store'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { API } from '../../../projectApi'
import { downloadFile } from '../../../utils/downloadFile'

const actions = {
  getProposalPDF:
    ({
      proposalId,
      email,
      sendEmail = false,
      downloadPDF = false
    }: {
      proposalId: number
      email?: string
      sendEmail?: boolean
      downloadPDF?: boolean
    }): AppThunk =>
    async () => {
      const { requestMessage, successMessage, failureMessage } = handleMessages(sendEmail, downloadPDF)
      const toast = setToastLoading(requestMessage)

      try {
        const blob = await API.TransactionHandler.Proposals.getPDFV2({ proposalId, email, sendEmail, downloadPDF })
        const fileName = `Cotización ${proposalId}.pdf`
        if (downloadPDF) downloadFile(blob, fileName)
        setToastSuccessUpdate(toast, { render: successMessage })
      } catch (e) {
        setToastErrorUpdate(toast, { render: failureMessage })
      }
    }
}

export default actions

const handleMessages = (sendEmail: boolean, downloadPDF: boolean) => {
  const requestMessage =
    sendEmail && downloadPDF
      ? 'Enviando y descargando PDF, por favor espere...'
      : sendEmail
      ? 'Enviando PDF por email, por favor espere...'
      : downloadPDF
      ? 'Descargando PDF, por favor espere...'
      : ''

  const successMessage =
    sendEmail && downloadPDF
      ? 'Descarga y envio del PDF exitoso'
      : sendEmail
      ? 'PDF enviado con éxito'
      : downloadPDF
      ? 'PDF descargado con éxito'
      : ''

  const failureMessage =
    sendEmail && downloadPDF
      ? 'Error al enviar email y descargar el PDF'
      : sendEmail
      ? 'Error al enviar el PDF'
      : downloadPDF
      ? 'Error al descargar el PDF'
      : ''
  return { requestMessage, successMessage, failureMessage }
}
