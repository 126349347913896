import { list } from './list'
import { sendEmail } from './sendEmail'
import { getById } from './getById'
import { getFromProspect } from './getFromProspect'
import { create } from './create'
import { metrics } from './metrics'
import { getPDF } from './pdf'
import { sendProposalsCSV } from './sendProposalsCSV'
import { metrics as tableMetrics } from './tableMetrics'
import { getPDFV2 } from './pdfV2'

export const ProposalsAPI = {
  create,
  list,
  sendEmail,
  getById,
  getPDF,
  getPDFV2,
  getFromProspect,
  metrics,
  sendProposalsCSV,
  tableMetrics
}
