import { AxiosResponse } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

export interface GetByIdParams {
  proposalId: string | number
  email?: string
  sendEmail?: boolean
  downloadPDF?: boolean
}

export function getPDFV2({ proposalId, email, sendEmail = false, downloadPDF = false }: GetByIdParams): Promise<Blob> {
  const params = snakecaseKeys({ email, sendEmail, downloadFile: downloadPDF })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/proposal/pdf/${proposalId}/v2`, {
      responseType: downloadPDF ? 'blob' : 'json',
      params
    })
    .then((response: AxiosResponse<Blob>) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
