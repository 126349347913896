// ESTE COMPONENTE ESTA EN DESARROLLO TODAVIA...

import React from 'react'
import InputGroup from '../../../../components/InputGroup'
import { Button, Col, Input, Row, Spin } from 'antd'
import { formatMoneyWithCountry, formatNumber } from '../../../../utils/formatNumber'
import styles from './Summary.module.scss'
import { M3Label } from '../../utils'
import { applyDiscount, getSubtotalCost } from '../../selectors/cost'
import {
  getRoundedTotalDisassemblePrice,
  getRoundedTotalFloorsPrice,
  getRoundedTotalKG,
  getRoundedTotalM3,
  getRoundedTotalPackagingPrice,
  getRoundedTotalPrice
} from '../../selectors/items'
import { PRICE_BY_VALUES } from '../../constants'
import { SelectedItem } from '../../../../sections/Deposits/types/EditDepositViewTypes/items'
import { useCountryInfo } from '../../../../components/CountrySelector/hooks/hooks'
import { TransportDetail } from '../../../../projectApi/TransactionHandler/Compute/cost'

interface GetTotalPricesProps {
  minBillableM3Price: number
  shipmentAssistants: {
    checkedAssistants: boolean
    shipmentAssistantCount: number
    shipmentAssistantPrice: number
  }
  transportDiscount: number
  depositDiscount: number
  additionalCost: number
  transportCost: number
  tollCostInCents: number
  selectedItems: SelectedItem[]
  serviceType: string
}

export interface SummaryProps extends Omit<GetTotalPricesProps, 'transportDiscount' | 'depositDiscount'> {
  discount: {
    discountCode: string
    setDiscountCode: ({ discountCode }: { discountCode: string }) => void
    fetchDiscount: (discountCode: string) => void
    loadingDiscount: boolean
    isValidDiscount: boolean
    transportDiscount: number
    depositDiscount: number
    errorDiscount: string
  }
  applyDiscount: boolean
  loadingStoragePrice?: boolean
  loadingTransportCost?: boolean
  countryCode: string
  onSave: (totalPrices: TotalPrices, transportCost?: TransportDetail) => void
  disabledEdit: boolean
  transportDetail?: TransportDetail
}

interface PriceProps {
  currency: string
  price: number
  loading?: boolean
  perMonth?: boolean
  negative?: boolean
}

interface DistanceOrSizeProps {
  unit: string
  amount: number
  loading?: boolean
}

const Summary = (summaryProps: SummaryProps) => {
  const {
    minBillableM3Price,
    shipmentAssistants,
    discount: {
      discountCode,
      loadingDiscount,
      setDiscountCode,
      fetchDiscount,
      isValidDiscount,
      transportDiscount,
      depositDiscount,
      errorDiscount
    },
    applyDiscount,
    loadingStoragePrice,
    loadingTransportCost,
    additionalCost,
    transportCost: transportCostProp,
    selectedItems,
    serviceType,
    onSave,
    countryCode,
    tollCostInCents,
    disabledEdit,
    transportDetail
  } = summaryProps

  const proposalCountry = useCountryInfo(countryCode)

  const totalPriceProps = {
    minBillableM3Price,
    shipmentAssistants,
    transportDiscount,
    depositDiscount,
    additionalCost,
    transportCost: transportCostProp,
    tollCostInCents,
    selectedItems,
    serviceType
  }

  const totalPrices = getTotalPrices(totalPriceProps)
  const {
    totalVolume,
    totalWeight,
    spaceCost,
    totalForPackaging,
    totalForDisassemble,
    totalForFloors,
    transportCost,
    tollCost,
    subTotal,
    discount,
    extraAssistantTotal,
    total
  } = totalPrices

  const totalTransportCost = transportCost && transportDetail ? transportCost + tollCost : transportCost

  return (
    <>
      <InputGroup title="Resumen">
        <Row>
          <Col span={14}>
            <Row className={styles.totalDimensionsContainer}>
              <Item title="Total Volumen" />
              <Item title="Total Peso" />
            </Row>
            <Row>
              <Item title="Valor Mensualidad" />
              <Item title="Valor Embalaje" />
              <Item title="Valor Desarmado" />
              <Item title="Valor por Escaleras (Pisos)" />
              <Item title="Valor por Asistentes" />
              <Item title="Valor Flete" />
              <Item title="Subtotal" />
              {transportDetail && transportCost ? (
                <>
                  <Item title="Distancia" />
                  <Item title="Volumen total de objetos" />
                  <Item title="Peso total de objetos" />
                  <Item title="Altura objeto más alto" />
                  <Item title="Largo objeto más largo" />
                  <Item title="Ancho objeto más ancho" />
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col span={10} className={styles.prices}>
            <Row className={styles.totalDimensionsContainer}>
              <p className={styles.values}>
                {formatNumber(totalVolume || 0, 2)} <M3Label />
              </p>
              <p className={styles.values}>{formatNumber(totalWeight || 0, 1)} kg</p>
            </Row>
            <Row>
              <Price loading={loadingStoragePrice} currency={proposalCountry.currency} price={spaceCost} perMonth />
              <Price currency={proposalCountry.currency} price={totalForPackaging} />
              <Price currency={proposalCountry.currency} price={totalForDisassemble} />
              <Price currency={proposalCountry.currency} price={totalForFloors} />
              <Price currency={proposalCountry.currency} price={extraAssistantTotal} />
              <Price loading={loadingTransportCost} currency={proposalCountry.currency} price={totalTransportCost} />
              <Price currency={proposalCountry.currency} price={subTotal + (totalTransportCost ? tollCost : 0)} />
              {transportDetail && transportCost ? (
                <>
                  <DistanceOrSize loading={loadingTransportCost} unit={'km'} amount={transportDetail?.distanceKm} />
                  <DistanceOrSize loading={loadingTransportCost} unit={'m³'} amount={transportDetail?.volumeM3} />
                  <DistanceOrSize loading={loadingTransportCost} unit={'kg'} amount={transportDetail?.maxWeightM} />
                  <DistanceOrSize loading={loadingTransportCost} unit={'m'} amount={transportDetail?.maxHeightM} />
                  <DistanceOrSize loading={loadingTransportCost} unit={'m'} amount={transportDetail?.maxLengthInM} />
                  <DistanceOrSize loading={loadingTransportCost} unit={'m'} amount={transportDetail?.maxWidthM} />
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col span={24} className={styles.discountCodeCol}>
            <p className={styles.inputTitle}>Código de descuento</p>
            <div className={styles.discountContainer}>
              <Input
                placeholder="Código de descuento"
                disabled={applyDiscount}
                value={discountCode}
                onChange={(e) => setDiscountCode({ discountCode: e.target.value })}
                onPressEnter={() => fetchDiscount(discountCode)}
              />
              {!isValidDiscount && (
                <Button
                  className={styles.discountButton}
                  type="primary"
                  loading={loadingDiscount}
                  onClick={() => fetchDiscount(discountCode)}>
                  Buscar
                </Button>
              )}
            </div>
            {errorDiscount && <div className={styles.error}>{errorDiscount}</div>}
            {isValidDiscount && (
              <div className={styles.errorContainer}>
                <span className={styles.valid}>¡Código válido!</span>
              </div>
            )}
          </Col>
          {isValidDiscount && (
            <>
              <Col span={16}>
                <p className={styles.labels}>Descuento</p>
              </Col>
              <Col span={8} className={styles.prices}>
                <Price currency={proposalCountry.currency} price={discount} negative loading={loadingDiscount} />
              </Col>
            </>
          )}
          <Col span={16}>
            <p className={styles.labels}>Total</p>
          </Col>
          <Col span={8} className={styles.prices}>
            <Price currency={proposalCountry.currency} price={total + (transportCost ? tollCost : 0)} />
          </Col>
          <Col span={24} className={styles.saveButtonContainer}>
            <Button
              loading={false}
              disabled={disabledEdit}
              type="primary"
              onClick={() => onSave(totalPrices, transportDetail)}>
              Guardar
            </Button>
            <div className={styles.errorContainer}>{false && <span className={styles.error}>{''}</span>}</div>
          </Col>
        </Row>
      </InputGroup>
    </>
  )
}

export default Summary

export const Price = ({ currency, loading = false, price, perMonth, negative }: PriceProps) => (
  <div className={styles.values}>
    {loading && (
      <span className={styles.transportCostSpinner}>
        <Spin size="small" />
      </span>
    )}
    {formatMoneyWithCountry(currency, price || 0, { decimals: 2, negative })}
    {perMonth && '/mes'}
  </div>
)

export const DistanceOrSize = ({ unit, loading = false, amount }: DistanceOrSizeProps) => (
  <div className={styles.values}>
    {loading && (
      <span className={styles.transportCostSpinner}>
        <Spin size="small" />
      </span>
    )}
    {formatNumber(amount || 0)} {unit}
  </div>
)

export const Item = ({ title }: { title: string }) => <p className={styles.labels}>{title}</p>

export type TotalPrices = {
  totalVolume: number
  totalWeight: number
  spaceCost: number
  totalForPackaging: number
  totalForDisassemble: number
  totalForFloors: number
  transportCost: number
  tollCost: number
  discount: number
  pricesWithDiscount: {
    spaceCostDiscounted: number
    transportCostDiscounted: number
  }
  subTotal: number
  extraAssistantTotal: number
  total: number
}

export const getTotalPrices = ({
  minBillableM3Price,
  shipmentAssistants,
  transportDiscount,
  depositDiscount,
  additionalCost,
  tollCostInCents,
  transportCost,
  selectedItems,
  serviceType
}: GetTotalPricesProps): TotalPrices => {
  switch (serviceType) {
    case PRICE_BY_VALUES.ITEMS:
    default: {
      const totalVolume = getRoundedTotalM3(selectedItems)
      const totalWeight = getRoundedTotalKG(selectedItems)
      const tollCost = tollCostInCents / 100
      const calculatedSpaceCost = getRoundedTotalPrice(selectedItems)

      const spaceCost = minBillableM3Price > calculatedSpaceCost ? minBillableM3Price : calculatedSpaceCost
      const totalForPackaging = getRoundedTotalPackagingPrice(selectedItems)
      const totalForDisassemble = getRoundedTotalDisassemblePrice(selectedItems)
      const totalForFloors = getRoundedTotalFloorsPrice(selectedItems)

      const extraAssistantTotal = shipmentAssistants.checkedAssistants
        ? shipmentAssistants.shipmentAssistantPrice * shipmentAssistants.shipmentAssistantCount
        : 0
      const subTotal = spaceCost + transportCost
      const subTotalWithExtras =
        subTotal + totalForPackaging + totalForDisassemble + totalForFloors + extraAssistantTotal

      const spaceCostDiscounted = applyDiscount(spaceCost, depositDiscount)
      const transportCostDiscounted = applyDiscount(transportCost, transportDiscount)

      const subTotalDiscounted =
        getSubtotalCost(spaceCostDiscounted, transportCostDiscounted) +
        totalForPackaging +
        totalForDisassemble +
        totalForFloors +
        extraAssistantTotal

      const discount = subTotalWithExtras - subTotalDiscounted

      const pricesWithDiscount = {
        spaceCostDiscounted,
        transportCostDiscounted
      }

      const total = subTotalDiscounted + additionalCost

      return {
        totalVolume,
        totalWeight,
        spaceCost,
        totalForPackaging,
        totalForDisassemble,
        totalForFloors,
        transportCost,
        tollCost,
        discount,
        pricesWithDiscount,
        subTotal: subTotalWithExtras,
        extraAssistantTotal,
        total
      }
    }
  }
}
