import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import snakecaseKeys from 'snakecase-keys'

type ExportPaymentsParams = {
  periodFrom?: Moment | null
  countryCode: string
}
export function exportPayments({ periodFrom, countryCode }: ExportPaymentsParams): Promise<void> {
  const dateFormatted = periodFrom?.format('YYYY-MM-DD')

  const params = snakecaseKeys({
    countryCode,
    periodFrom: dateFormatted
  })

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/cbu-payments/generate-txt`, null, {
      params
    })
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
