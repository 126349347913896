import React, { useMemo, useState } from 'react'
import styles from './singleDeposit.module.scss'
import InfoBanner, { Item } from '../../../components/infoBanner'
import SmallTabs, { Tab } from '../../../components/smallTabs'
import OverviewCard, { Header, Module } from '../../../components/overviewCard'
import { Camera, Check, ExclamationIcon, Menu, PercentageRound } from '../../../icons'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import VisibilitySwitcher from '../../../components/visibilitySwitcher'
import { SingleDepositItem, SingleDepositObject, SingleDepositState } from '../types/singleDeposit'
import { clone } from 'ramda'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { formatNumber } from '../../../utils/formatNumber'
import { STATUS_VALUES } from '../../../common/operationsTable/constants'
import clsx from 'clsx'
import { Tooltip } from 'antd'
import { Country } from '../../../components/CountrySelector/constants/constants'
import { PriceAnimator, ValidationOverviewCard } from '../../DepositsValidation/components/validationView'
import { ExpandableModule } from '../../../components/overviewCard/overviewCard'
import { ValidationViewObject } from '../../DepositsValidation/types/validationView'
import { BillingStatus } from '../../../projectApi/TransactionHandler/Billings/common'
import ImgWithCarousel from '../../../components/CarouselModal/components/ImgWithCarousel'
import { CopyButton } from '../../../components/CopyButton'

export const convertObjectsToValidationObjects = (operationObjects: SingleDepositObject[]): ValidationViewObject[] => {
  return clone(operationObjects)
    .sort((a, b) => a.object.product.id - b.object.product.id)
    .map((obj) => {
      return {
        objectId: obj.object.id,
        photos: obj.object.photos,
        productId: obj.object.product.id,
        productName: obj.object.product.name,
        storageCostInCents: obj.priceInCents,
        extras: {
          disassembly: {
            enabled: true,
            disassemblyCost: obj.disassemblyCost
          },
          stairs: {
            enabled: true,
            floors: obj.floors,
            floorsCostPerFloorInCents: obj.floorsCostPerFloorInCents
          },
          packaging: {
            enabled: true,
            packagingCostInCents: obj.packagingCostInCents
          }
        },
        deletedAt: obj.object.deletedAt
      }
    })
}

enum ViewTab {
  ITEMS = 'items',
  OBJECTS = 'objects'
}
interface SingleDepositObjectWithAmount extends SingleDepositObject {
  amount: number
  totalStairsCostInCents: number
  [key: string]: unknown
}

type SingleDepositProps = SingleDepositState & {
  countries: Country[]
  openRemittanceModal: (transactionId: number) => void
}

const SingleDeposit: React.FC<SingleDepositProps> = ({
  countries,
  operationCountryCode,
  items,
  baseInfo,
  loading,
  discountCode,
  objects,
  transportCost,
  legacyAdditionalCost,
  extras,
  billings,
  itemsM3,
  openRemittanceModal
}) => {
  const [activeTab, setActiveTab] = useState<ViewTab>(ViewTab.ITEMS)
  const [objectsExpanded, setObjectsExpanded] = useState(false)

  const currencySymbol = useMemo(
    () => countries.find((c) => c.code === operationCountryCode)?.currency,
    [operationCountryCode]
  )

  const itemColumns: Column<SingleDepositItem>[] = [
    {
      key: 'description',
      label: 'Producto',
      minWidth: '150px',
      alignment: 'left',
      renderDataCell: ({ productId, description }) => (
        <span style={{ textAlign: 'left' }}>
          {productId} - {description}
        </span>
      )
    },
    {
      key: 'packagingCost',
      label: (
        <span style={{ textAlign: 'center' }}>
          Embalaje
          <br />
          (unitario)
        </span>
      ),
      renderDataCell: ({ packagingCost }) => formatNumber(packagingCost)
    },
    {
      key: 'costPerFloorInCents',
      label: (
        <span style={{ textAlign: 'center' }}>
          Escalera
          <br />
          (unitario)
        </span>
      ),
      renderDataCell: ({ costPerFloorInCents }) => formatNumber(costPerFloorInCents / 100)
    },
    {
      key: 'disassemblyCost',
      label: (
        <span style={{ textAlign: 'center' }}>
          Desarmado
          <br />
          (unitario)
        </span>
      ),
      renderDataCell: ({ disassemblyCost }) => formatNumber(disassemblyCost)
    },
    {
      key: 'storage',
      label: (
        <span style={{ textAlign: 'center' }}>
          Almacenaje
          <br />
          (unitario)
        </span>
      ),
      renderDataCell: ({ price }) => formatNumber(price)
    },
    { key: 'amount', label: 'Cantidad' }
  ]

  const objectColumns: Column<SingleDepositObjectWithAmount>[] = [
    {
      key: 'photos',
      label: <Camera />,
      renderDataCell: ({ object }) => (
        <ImgWithCarousel carouselImages={object.photos.map((p) => p.url)} alt={object.product.name} />
      )
    },
    {
      key: 'object.id',
      label: 'ID Objeto',
      renderDataCell: ({ object, amount }) => (amount === 1 ? object.id : '...')
    },
    {
      key: 'name',
      label: 'Nombre producto',
      minWidth: '150px',
      alignment: 'left',
      renderDataCell: ({ object: { product } }) => (
        <span style={{ textAlign: 'left' }}>
          {product.id} - {product.name}
        </span>
      )
    },
    {
      key: 'packagingCostInCents',
      label: (
        <span style={{ textAlign: 'center' }}>
          Embalaje
          <br />({objectsExpanded ? 'unitario' : 'total'})
        </span>
      ),
      renderDataCell: ({ packagingCostInCents }) => {
        return formatNumber(packagingCostInCents / 100)
      }
    },
    {
      key: 'stairs',
      label: (
        <span style={{ textAlign: 'center' }}>
          Escalera
          <br />({objectsExpanded ? 'unitario' : 'total'})
        </span>
      ),
      renderDataCell: ({ floorsCostPerFloorInCents, floors, totalStairsCostInCents }) => (
        <span>
          {objectsExpanded && floors > 0 ? (
            <span>
              {formatNumber(floorsCostPerFloorInCents / 100)} x {floors} pisos
            </span>
          ) : (
            formatNumber(totalStairsCostInCents / 100)
          )}
        </span>
      )
    },
    {
      key: 'disassemblyCost',
      label: (
        <span style={{ textAlign: 'center' }}>
          Desarmado <br />({objectsExpanded ? 'unitario' : 'total'})
        </span>
      ),
      renderDataCell: ({ disassemblyCost }) => formatNumber(disassemblyCost)
    },
    {
      key: 'priceInCents',
      label: (
        <span style={{ textAlign: 'center' }}>
          Almacenaje
          <br />({objectsExpanded ? 'unitario' : 'total'})
        </span>
      ),
      renderDataCell: ({ priceInCents }) => formatNumber(priceInCents / 100)
    },
    { key: 'amount', label: 'Cantidad' }
  ]

  const compoundObjectRows: SingleDepositObjectWithAmount[] = useMemo(
    () =>
      Object.values(
        objects.reduce<{ [productId: string]: SingleDepositObjectWithAmount }>((acc, current) => {
          const anotherObjectWithSameProductId = clone(acc[current.object.product.id])
          if (!anotherObjectWithSameProductId) {
            acc[current.object.product.id] = {
              ...clone(current),
              amount: 1,
              totalStairsCostInCents: current.floorsCostPerFloorInCents * current.floors
            }
            return acc
          }

          const accWithSummedPrices = {
            ...acc,
            [current.object.product.id]: {
              ...anotherObjectWithSameProductId,
              disassemblyCost: anotherObjectWithSameProductId.disassemblyCost + current.disassemblyCost,
              packagingCostInCents: anotherObjectWithSameProductId.packagingCostInCents + current.packagingCostInCents,
              priceInCents: anotherObjectWithSameProductId.priceInCents + current.priceInCents,
              floors: 0,
              floorsCostPerFloorInCents: 0,
              totalStairsCostInCents:
                anotherObjectWithSameProductId.totalStairsCostInCents +
                current.floorsCostPerFloorInCents * current.floors,
              amount: anotherObjectWithSameProductId.amount + 1
            }
          }

          return accWithSummedPrices
        }, {})
      ).sort((a, b) => b.amount - a.amount),
    [objects]
  )

  const expandedObjectRows: SingleDepositObjectWithAmount[] = useMemo(
    () =>
      objects
        .map((o) => ({ ...o, amount: 1, totalStairsCostInCents: o.floorsCostPerFloorInCents * o.floors }))
        .sort(
          (a, b) =>
            compoundObjectRows.findIndex((o) => o.object.product.id === a.object.product.id) -
            compoundObjectRows.findIndex((o) => o.object.product.id === b.object.product.id)
        ),
    [objects, compoundObjectRows]
  )

  const objectRows = objectsExpanded ? expandedObjectRows : compoundObjectRows

  if (loading)
    return (
      <div className={styles.placeholder}>
        <LoadingIndicator />
      </div>
    )
  if (!baseInfo.id)
    return (
      <div className={styles.placeholder}>
        <span>Hubo un error buscando la información.</span>
      </div>
    )

  const validated = baseInfo.status === STATUS_VALUES.VALIDATED
  const getBadge = () => {
    const { text, Icon, tooltip } = {
      text: validated ? 'Validada' : 'No validada',
      tooltip: validated
        ? 'Esta operación ha sido validada y los valores de los objetos ya han sido confirmados.'
        : 'Dado que esta operación aún no ha sido validada, los valores de los objetos no han sido confirmados.',
      Icon: validated ? <Check width="14px" height="14px" fill="#969696" /> : <ExclamationIcon />
    }

    return (
      <Tooltip title={tooltip} placement={'bottom'}>
        <div className={clsx(styles.validationBadge, validated && styles.validated)}>
          {Icon}
          <span>{text}</span>
        </div>
      </Tooltip>
    )
  }

  const relevantBilling =
    billings.find(({ status, authorizationEnabled }) =>
      validated ? authorizationEnabled : BillingStatus[status] === BillingStatus.PROCESSED && !authorizationEnabled
    ) || billings[0]

  const totalTransportCost = transportCost.remittance + transportCost.tollCostInCents / 100

  return (
    <div className={styles.singleDeposit}>
      <InfoBanner title="Detalle">
        <Item heading="TX Asociada" value={baseInfo.transactionId} />
        <Item heading="ID Ingreso" value={baseInfo.id} />
        <Item heading="ID Cliente" value={baseInfo.user.id} />
        <Item heading="Nombre" value={baseInfo.user.name} />
        <Item heading="Apellido" value={baseInfo.user.lastName} />
        <Item
          heading="F. Operación"
          value={baseInfo.createdAt ? baseInfo.createdAt.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item
          heading="F. Servicio"
          value={baseInfo.datetime ? baseInfo.datetime.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item heading="Remito" value="Ver remito" onValueClick={() => openRemittanceModal(baseInfo.transactionId)} />
        <Item heading="Dirección completa" value={baseInfo.fullAddress}>
          <CopyButton textToCopy={baseInfo.fullAddress} tooltipText="Copiado!" />
        </Item>
      </InfoBanner>

      <div className={styles.tabBar}>
        <SmallTabs activeTabId={activeTab} onChange={(newId) => setActiveTab(newId)}>
          <Tab id={ViewTab.ITEMS}>Items cotizados</Tab>
          <Tab id={ViewTab.OBJECTS}>Objetos reales</Tab>
        </SmallTabs>
        {getBadge()}
        <VisibilitySwitcher
          activeId={activeTab}
          items={[
            {
              id: ViewTab.OBJECTS,
              node: (
                <button
                  disabled={objectRows.length === objects.length && !objectsExpanded}
                  className={styles.expanderButton}
                  onClick={() => setObjectsExpanded(!objectsExpanded)}>
                  <Menu />
                  {objectsExpanded ? 'Ocultar' : 'Ver'} detalles
                </button>
              )
            }
          ]}
        />
      </div>
      <div className={styles.overview}>
        <VisibilitySwitcher
          activeId={activeTab}
          items={[
            {
              id: ViewTab.ITEMS,
              node: (
                <OverviewCard>
                  <Header extraInfo="Valor total en base a las descripciones realizadas por el cliente.">
                    Valor cotizado
                  </Header>
                  <Module className={styles.priceGrid}>
                    <span>Items cotizados</span>
                    <span>
                      <b>{items.reduce((acc, item) => acc + item.amount, 0)}</b> ({itemsM3 && formatNumber(itemsM3, 3)}{' '}
                      m3)
                    </span>
                    <span>Almacenaje mensual</span>
                    <span>
                      <PriceAnimator
                        value={items.reduce((acc, item) => acc + item.price * item.amount, 0)}
                        currencySymbol={currencySymbol}
                      />
                    </span>
                  </Module>
                  <ExpandableModule
                    title={
                      <div className={styles.subtotal}>
                        <span>Costos adicionales</span>
                        <span>
                          <PriceAnimator
                            value={
                              transportCost.remittance +
                              transportCost.tollCostInCents / 100 +
                              legacyAdditionalCost +
                              items.reduce((acc, item) => {
                                return (
                                  acc +
                                  (item.disassemblyCost + item.packagingCost + item.costPerFloorInCents / 100) *
                                    item.amount
                                )
                              }, 0)
                            }
                            currencySymbol={currencySymbol}
                          />
                        </span>
                      </div>
                    }>
                    <div className={styles.priceGrid}>
                      <span>Transporte cotizado</span>
                      <PriceAnimator value={totalTransportCost} currencySymbol={currencySymbol} />
                      <span>Escaleras</span>
                      <PriceAnimator
                        value={items.reduce((acc, item) => acc + (item.costPerFloorInCents / 100) * item.amount, 0)}
                        currencySymbol={currencySymbol}
                      />
                      <span>Embalaje</span>
                      <PriceAnimator
                        value={items.reduce((acc, item) => acc + item.packagingCost * item.amount, 0)}
                        currencySymbol={currencySymbol}
                      />
                      <span>Desarmado</span>
                      <PriceAnimator
                        value={items.reduce((acc, item) => acc + item.disassemblyCost * item.amount, 0)}
                        currencySymbol={currencySymbol}
                      />
                      {Boolean(legacyAdditionalCost) && (
                        <>
                          <span>Otros adicionales</span>
                          <PriceAnimator value={legacyAdditionalCost} currencySymbol={currencySymbol} />
                        </>
                      )}
                    </div>
                  </ExpandableModule>

                  {discountCode.length > 0 && (
                    <Module className={styles.discountCodeModule}>
                      <PercentageRound />
                      <span>Código de descuento: {discountCode}</span>
                    </Module>
                  )}
                  <Module className={styles.priceGrid}>
                    <span className={styles.total}>Total ingreso cotizado</span>
                    <PriceAnimator
                      value={
                        transportCost.remittance +
                        transportCost.tollCostInCents / 100 +
                        legacyAdditionalCost +
                        items.reduce((acc, item) => {
                          return (
                            acc +
                            (item.disassemblyCost + item.packagingCost + item.price + item.costPerFloorInCents / 100) *
                              item.amount
                          )
                        }, 0)
                      }
                      currencySymbol={currencySymbol}
                      className={clsx(styles.total, styles.totalNumber)}
                    />
                  </Module>
                </OverviewCard>
              )
            },
            {
              id: ViewTab.OBJECTS,
              node: (
                <ValidationOverviewCard
                  quotedTransportCost={transportCost.real}
                  extraHeaderInfo="Valor final tomando como referencia los objetos ingresados al depósito"
                  currencySymbol={currencySymbol}
                  discountCode={discountCode}
                  extraPrices={{
                    assistants: {
                      enabled: true,
                      price: extras.shipmentAssistantPrice,
                      count: extras.shipmentAssistantCount
                    },
                    kmsExtra: {
                      enabled: true,
                      price: extras.kmsExtraPrice,
                      count: extras.kmsExtra
                    },
                    transport: {
                      enabled: true,
                      price: extras.transportExtra,
                      tollCostInCents: transportCost.tollCostInCents
                    }
                  }}
                  objects={convertObjectsToValidationObjects(objects)}
                  totalM3={relevantBilling?.m3}
                  provisionalBilling={
                    relevantBilling?.startDate && relevantBilling.endDate
                      ? {
                          ...relevantBilling,
                          startDate: relevantBilling.startDate,
                          endDate: relevantBilling.endDate,
                          value: relevantBilling.totalPrice
                        }
                      : undefined
                  }
                />
              )
            }
          ]}
        />
      </div>
      <VisibilitySwitcher
        activeId={activeTab}
        items={[
          {
            id: ViewTab.ITEMS,
            node: (
              <DynamicTable
                columns={itemColumns}
                rows={items}
                loading={loading}
                keyExtractor={({ guid }) => guid}
                customClassNames={{
                  table: styles.customTableProps
                }}
                customStrings={{
                  emptyState: 'No se encontraron items cotizados.'
                }}
              />
            )
          },
          {
            id: ViewTab.OBJECTS,
            node: (
              <DynamicTable
                columns={objectColumns}
                rows={objectRows}
                loading={loading}
                keyExtractor={({ object }) => object.id}
                customClassNames={{
                  table: styles.customTableProps
                }}
                customStrings={{
                  emptyState: 'No se encontraron objetos cargados.'
                }}
              />
            )
          }
        ]}
      />
    </div>
  )
}

export default SingleDeposit
